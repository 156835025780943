<template>
  <div v-if="loader" style=" text-align: center; margin-top: 20%; ">
    <b-spinner type="grow" label="Loading..."></b-spinner>
  </div>
  <div v-else>
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Client {{client.raison_social}}</h3>
        </div>
        <div class="card-toolbar">
          <b-button variant="success" size="sm" class="btn-icon mr-1" @click="save" ><i class="icon-md fas fa-save"></i></b-button>
        </div>
      </div>
    </div>
    <div class="card-deck">
      <div class="card card-custom gutter-b">
        <div class="card-header">
          <div class="card-title">
            <h3 class="card-label">Information du client</h3>
          </div>
        </div>
        <div class="card-body">
          <b-row>
            <b-col md=6>
              <b-form-group label="Raison Social:">
                <b-form-input v-model="client.raison_social"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md=6>
              <b-form-group label="Nom:">
                <b-form-input v-model="client.nom"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md=6>
              <b-form-group label="Prenom:">
                <b-form-input v-model="client.prenom"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md=6>
              <b-form-group label="Email:">
                <b-form-input v-model="client.email"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md=6>
              <b-form-group label="Telephone:">
                <b-form-input v-model="client.telephone"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md=6>
              <b-form-group label="Statut:">
                <select class="form-control" v-model="client.status">
                  <option v-for="(s,i) in params.status" :key="i" :value="s.id">{{s.titre}}</option>
                </select>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Commentaire</h3>
            </div>
          </div>
          <div class="card-body">
            <b-form-group label="Commentaire:">
              <b-form-textarea v-model="client.commentaire" rows="4" readonly></b-form-textarea>
            </b-form-group>
            <b-form-group label="Ajouter:">
              <b-input-group>
                <b-form-input v-model="commentaireTmp" type="text" required placeholder="Entre votre commentaire"></b-form-input>
                <template #append>
                  <b-button variant="success" class="btn-icon mr-1" @click="addCommentaire()"><i class="icon-md fas fa-plus"></i></b-button>
                </template>
              </b-input-group>
            </b-form-group>
          </div>
        </div>
    </div>
    <div class="card card-custom gutter-b mt-4" v-if="$store.getters.currentUser.role === 1">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Contrats</h3>
        </div>
        <div class="card-toolbar">
          <b-button variant="primary" size="sm" class="btn-icon mr-1" @click="openContrat(null)"><i class="icon-md fas fa-plus"></i></b-button>
        </div>
      </div>
      <div class="card-body">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Total HT</th>
              <th scope="col">Total TTC</th>
              <th scope="col">Jour prelevement</th>
              <th scope="col">Date Debut</th>
              <th scope="col">Type du contrat</th>
              <th scope="col">Action</th>
              <th scope="col">Date create</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(p,i) in client.produits" :key="i">
              <th scope="row">{{i+1}}</th>
              <td>{{getTotal(p,false)}}</td>
              <td>{{getTotal(p,true)}}</td>
              <td>
                <div v-if="p.produit_type_id === 1">
                  le {{p.jour_mois}} du mois
                </div>
                <div v-else>
                  Non
                </div>
              </td>
              <td>{{p.date_debut}}</td>
              <td>{{params.ProduitType.find(t => t.id === p.produit_type_id).titre}}</td>
              <td>
                <b-button variant="success" size="xs" class="btn-icon mr-1" @click="openContrat(i)"><i class="icon-md fas fa-eye"></i></b-button>
                <b-button variant="danger" size="xs" class="btn-icon mr-1" @click="deleteContrat(i)"><i class="icon-md fas fa-trash"></i></b-button>
              </td>
              <td>{{$store.back.timestampToDate(p.date_create)}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <b-modal size="lg" id="modal-detail" title="Detail du contrat">
      <b-row>
        <b-col md=6>
          <b-form-group label="TVA:">
            <b-form-input v-model.number="contratTmp.tva"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md=6>
          <b-form-group label="Type de contrat:">
            <select class="form-control" v-model.number="contratTmp.produit_type_id">
              <option :value="null" disabled>Choisissez</option>
              <option v-for="(t,i) in params.ProduitType" :value="t.id" :key="i">{{t.titre}}</option>
            </select>
          </b-form-group>
        </b-col>
        <b-col md=6 v-if="contratTmp.produit_type_id === 1">
          <b-form-group label="Jour du prelevement:">
            <b-form-input v-model.number="contratTmp.jour_mois" type="number"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md=6>
          <b-form-group label="Devise:">
            <select class="form-control" v-model.number="contratTmp.devise_id">
              <option :value="null" disabled>Choisissez</option>
              <option v-for="(d,i) in params.Devise" :value="d.id" :key="i">{{d.titre}} ({{d.symbole}})</option>
            </select>
          </b-form-group>
        </b-col>
        <b-col md=6>
          <b-form-group label="Date debut:">
            <b-form-datepicker v-model="contratTmp.date_debut"></b-form-datepicker>
          </b-form-group>
        </b-col>
        <b-col md=6>
          <b-form-group label="Date fin:">
            <b-form-datepicker v-model="contratTmp.date_fin"></b-form-datepicker>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col offset-md="9" md="3">
          <b-button block variant="primary" class="mr-1" @click="addDetail()" ><i class="icon-md fas fa-plus"></i></b-button>
        </b-col>
      </b-row>
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">Titre</th>
            <th scope="col">Quantite</th>
            <th scope="col">Prix HT</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(d,i) in contratTmp.details" :key="i">
            <td><b-form-input v-model="d.titre"></b-form-input></td>
            <td><b-form-input v-model="d.quantite"></b-form-input></td>
            <td><b-form-input v-model.number="d.prix_ht"></b-form-input></td>
            <td><b-button variant="danger" size="sm" class="btn-icon mr-1" @click="deleteDetail(i)"><i class="icon-md fas fa-trash"></i></b-button></td>
          </tr>
        </tbody>
      </table>
    </b-modal>
  </div>
</template>

<script>
export default {
  data(){
    return {
      loader:true,
      params:{
        Devise:[],
        ProduitType:[],
        status:[]
      },
      commentaireTmp:null,
      client:{
        "id": null,
        "raison_social": null,
        "nom": null,
        "prenom": null,
        "email": null,
        "telephone":null,
        "produits": [],
        "user_id": null,
        "commentaire":"",
        "date_create": null,
        "date_update": null,
        "status": 1
      },
      contratTmp:{
        "date_create": null,
        "date_debut":null,
        "date_fin":null,
        "tva": null,
        "produit_type_id": null,
        "jour_mois": null,
        "devise_id": null,
        "details": []
      }
    }
  },
  components: {
  },
  watch: {
  },
  methods: {
    save() {
      this.$store.back.ajax('/client/create', this.client, (res) => {
        if(res.status === true){
          this.client = res.data
          this.$bvToast.toast(`Vos modification sont sauvegarde !`, {
            title: 'Sauvegarder !',
            variant: 'success',
            solid: true
          });
        }
      });
    },
    addCommentaire(){
      var user = this.$store.back.getUser();
      var date = new Date();
      this.client.commentaire += `${this.commentaireTmp} | ${user.prenom} ${user.nom} ${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`+"\n";
      this.commentaireTmp = null;
    },
    getTotal(produit,tva){
      var total_ht = 0;
      produit.details.forEach((item) => {
        total_ht += item.prix_ht*item.quantite;
      });
      var devise = this.params.Devise.find(d => d.id === produit.devise_id);
      if(devise === undefined){
        devise = this.params.Devise[0];
      }
      if(tva === false){
        return total_ht+devise.symbole;
      }else{
        return total_ht*((100+produit.tva)/100)+devise.symbole;
      }
    },
    openContrat(i = null){
      if(i === null){
        this.client.produits.push({
          "date_create": parseInt(new Date().getTime()/1000),
          "date_debut":null,
          "date_fin":null,
          "tva": null,
          "produit_type_id": null,
          "jour_mois": null,
          "devise_id": null,
          "details": []
        });
        i = this.client.produits.length-1;
      }
      this.contratTmp = this.client.produits[i];
      this.$root.$emit('bv::toggle::modal', 'modal-detail', '#btnToggle')
    },
    deleteContrat(i){
      this.client.produits.splice(i, 1);
    },
    addDetail(){
      this.contratTmp.details.push({
        "titre": null,
        "quantite": 1,
        "prix_ht": null
      })
    },
    deleteDetail(i){
      this.contratTmp.details.splice(i, 1);
    }
  },
  computed: {

  },
  beforeMount(){
    let params = {
      modules: ["Devise","ProduitType"]
    };
    this.$store.back.ajax('/magic/all', params, (res) => {
      if(res.status === true){
        this.params.Devise = res.data['Devise'];
        this.params.ProduitType = res.data['ProduitType'];
        this.params.status = res.data['status'];
      }
      this.loader = false
    });
    if(this.$route.query.client_id != undefined){
      this.$store.back.ajax(`/client/${this.$route.query.client_id}`, null, (res) => {
        if(res.status === true){
          this.client = res.data;
        }
      });
    }
  },
  mounted() {

  }
};
</script>
